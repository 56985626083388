<template>
  <CHeader position="sticky" :class="headerClassNames">
    <CContainer class="border-bottom px-4" fluid>
      <CHeaderToggler
        style="margin-inline-start: -14px"
        @click="sidebar.toggleVisible()"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <!-- CHeaderNav class="d-none d-md-flex">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Users</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Settings</CNavLink>
        </CNavItem>
      </CHeaderNav -->
      <!-- CHeaderNav class="ms-auto">
        <CNavItem>
          <CNavLink href="#">
            <CIcon icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem>
      </CHeaderNav -->
      <CHeaderNav>
        <!-- li class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </li -->
        <CDropdown variant="nav-item" placement="bottom-end" class="mx-4">
          <CDropdownToggle>
            <CIcon class="me-2" icon="cil-loop-circular" size="lg" />

            <CBadge
              v-if="getProcessingAsyncJobCount"
              color="danger"
              position="top-end"
              shape="rounded-pill"
            >
              {{ getProcessingAsyncJobCount }}
            </CBadge>
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdown
              v-if="!asyncJobs || asyncJobs.length === 0"
              class="no-async-jobs"
            >
              {{ $t("common.text.no_job_in_progress") }}
            </CDropdown>
            <CDropdownItem v-else color="info">
              {{ $t("common.text.job_in_progress") }}
            </CDropdownItem>
            <CDropdownItem
              v-for="(asyncJob, asyncJobIndex) in asyncJobs"
              :key="'async-job-list-' + asyncJobIndex"
              class="align-items-center async-job-item"
              :style="
                'background-color: var(--cui-' +
                getAsyncJobColor(asyncJob) +
                ')'
              "
            >
              <div class="async-job-title">
                {{ asyncJob.nm }} - {{ $t("code.nm." + asyncJob.sttusCode) }}

                <CButton
                  class="float-end btn btn-secondary"
                  type="button"
                  size="sm"
                  @click="hideAsyncJob($event, asyncJob)"
                >
                  <CIcon icon="cil-x" />
                </CButton>
                <CButton
                  v-if="isProgressingAsyncJob(asyncJob)"
                  class="float-end btn btn-secondary mx-2"
                  type="button"
                  size="sm"
                  @click="cancelAsyncJob($event, asyncJob)"
                >
                  {{ $t("common.button.cancel") }}
                </CButton>
              </div>
              <div v-if="asyncJob.failResn" class="async-job-fail-reason">
                {{ $t("code.nm.notification_purpose.error_report") }} :
                <pre>{{ asyncJob.failResn }}</pre>
              </div>
              <CProgress
                v-if="asyncJob.allCo"
                :value="Math.floor((100 * asyncJob.processCo) / asyncJob.allCo)"
                >{{
                  Math.floor((100 * asyncJob.processCo) / asyncJob.allCo)
                }}%({{ asyncJob.processCo }}/{{ asyncJob.allCo }})</CProgress
              >
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
        <CDropdown variant="nav-item" placement="bottom-end">
          <CDropdownToggle :caret="false">
            <CIcon v-if="colorMode === 'dark'" icon="cil-moon" size="lg" />
            <CIcon v-else-if="colorMode === 'light'" icon="cil-sun" size="lg" />
            <CIcon v-else icon="cil-contrast" size="lg" />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem
              :active="colorMode === 'light'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('light')"
            >
              <CIcon class="me-2" icon="cil-sun" size="lg" />
              {{ $t("appheader.button.light") }}
            </CDropdownItem>
            <CDropdownItem
              :active="colorMode === 'dark'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('dark')"
            >
              <CIcon class="me-2" icon="cil-moon" size="lg" />
              {{ $t("appheader.button.dark") }}
            </CDropdownItem>
            <!-- CDropdownItem
              :active="colorMode === 'auto'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('auto')"
            >
              <CIcon class="me-2" icon="cil-contrast" size="lg" /> {{ $t('appheader.button.auto') }}
            </CDropdownItem -->
          </CDropdownMenu>
        </CDropdown>
        <li class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </li>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useColorModes } from "@coreui/vue";

import { CContainer } from "@coreui/vue/dist/esm/components/grid/index.js";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/vue/dist/esm/components/dropdown/index.js";
import {
  CHeader,
  CHeaderNav,
  CHeaderToggler,
} from "@coreui/vue/dist/esm/components/header/index.js";
// eslint-disable-next-line import/no-named-as-default
import CIcon from "@coreui/icons-vue";
import { CButton } from "@coreui/vue/dist/cjs/components/button/index.js";
import { useSidebarStore } from "~/stores/sidebar.js";
import AppHeaderDropdownAccnt from "@/components/AppHeaderDropdownAccnt.vue";
import { useAsyncJobStore } from "~/stores/asyncJob";
import { customFetch } from "~/composables/customFetch";

const headerClassNames = ref("mb-4 p-0");
const { colorMode, setColorMode } = useColorModes(
  "coreui-free-vue-admin-template-theme",
);
const sidebar = useSidebarStore();

const asyncJobStore = useAsyncJobStore();
const asyncJobs = ref([]);
onMounted(() => {
  document.addEventListener("scroll", () => {
    if (document.documentElement.scrollTop > 0) {
      headerClassNames.value = "mb-4 p-0 shadow-sm";
    } else {
      headerClassNames.value = "mb-4 p-0";
    }
  });
});
const getAsyncJobColor = (asyncJob) => {
  switch (asyncJob.sttusCode) {
    case "async_job_status.waiting":
      return "secondary";
    case "async_job_status.ready":
    case "async_job_status.processing":
      return "info";
    case "async_job_status.success":
      return "success";
    case "async_job_status.cancelled":
      return "warning";
    case "async_job_status.fail":
      return "danger";
  }
};
const isProgressingAsyncJob = (asyncJob) => {
  switch (asyncJob.sttusCode) {
    case "async_job_status.waiting":
    case "async_job_status.ready":
    case "async_job_status.processing":
      return true;
  }
  return false;
};
const cancelAsyncJob = async (e, asyncJob) => {
  e.preventDefault();
  e.stopPropagation();
  const response = await customFetch("/async/cancel?token=" + asyncJob.token, {
    method: "GET",
  });
  if (response) {
    if (response.message) {
      makeToast(response.message);
    }
  }
};
const hideAsyncJob = async (e, asyncJob) => {
  e.preventDefault();
  e.stopPropagation();
  const response = await customFetch("/async/hide?token=" + asyncJob.token, {
    method: "GET",
  });
  if (response.resultCode === "success") {
    const asyncJobStore = useAsyncJobStore();
    await asyncJobStore.checkAsyncJobs(false);
  }
};
const getProcessingAsyncJobCount = computed(() => {
  if (asyncJobs.value && asyncJobs.value.length) {
    let count = 0;
    for (let i = 0; i < asyncJobs.value.length; i++) {
      const asyncJob = asyncJobs.value[i];
      if (isProgressingAsyncJob(asyncJob)) {
        count++;
      }
    }
    return count;
  }
  return 0;
});
watch(
  () => asyncJobStore.getAsyncJobs,
  (newVal) => {
    asyncJobs.value = newVal;
  },
  { deep: true, immediate: true },
);
</script>

<style scoped>
.async-job-title {
  padding: 0.3rem 0;
}
.async-job-title,
.async-job-fail-reason {
  font-size: 0.8rem;
  max-width: 70vw;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 4.4rem;
}
.async-job-item {
  border-top: var(--cui-border-width) var(--cui-border-style)
    var(--cui-border-color);
}
.no-async-jobs {
  text-align: center;
  padding: 2rem;
  white-space: nowrap;
}
</style>
