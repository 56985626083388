export function getDataFromDataValueObject(obj: any) {
  if (!obj) {
    return;
  }
  if (obj.data && obj.value) {
    return obj.data;
  }
  return obj;
}

export function getNestedProperty(obj: any, path: any) {
  if (!path) {
    return obj;
  }
  if (!obj) {
    return;
  }
  const keys = path.split(".");

  return keys.reduce((acc: any, key: any) => {
    let ret;
    if (Array.isArray(acc) && acc.length > 0) {
      ret = getDataFromDataValueObject(getDataFromDataValueObject(acc[0])[key]);
    } else if (acc && acc[key]) {
      ret = getDataFromDataValueObject(acc[key]);
    }
    return ret;
  }, obj);
}

export function deepEqual(obj1: any, obj2: any, ignoreKeys: any = undefined) {
  // 값이 동일한 경우 (기본형인 경우)
  if (obj1 === obj2) {
    return true;
  }

  // 둘 중 하나가 null 또는 객체가 아닌 경우 (기본형 값이거나 null)
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // 두 객체의 키 배열을 비교
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // 각 키의 값을 재귀적으로 비교
  for (const key of keys1) {
    if (ignoreKeys && ignoreKeys.indexOf(key) >= 0) {
      continue;
    }
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key], ignoreKeys)) {
      return false;
    }
  }

  return true;
}

export function softDeepEqual(obj1, obj2) {
  // 불필요한 값을 제거한 새로운 객체 생성
  function filterObject(obj) {
    if (typeof obj !== "object" || obj === null) return obj;

    if (Array.isArray(obj)) {
      return obj.map(filterObject).filter((item) => item !== undefined);
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      const filteredValue = filterObject(value);
      if (
        filteredValue !== null &&
        filteredValue !== undefined &&
        filteredValue !== "" &&
        key !== "__expanded__" &&
        !(Array.isArray(filteredValue) && filteredValue.length === 0)
      ) {
        acc[key] = filteredValue;
      }
      return acc;
    }, {});
  }

  // 정제된 객체를 비교
  const cleanedObj1 = filterObject(obj1);
  const cleanedObj2 = filterObject(obj2);

  // JSON 문자열 비교로 객체의 순서를 고려한 단순 비교
  return JSON.stringify(cleanedObj1) === JSON.stringify(cleanedObj2);
}

export function deepCopy(src: any, dst: any) {
  // src가 null이거나 객체가 아니면 단순히 src를 반환
  if (src === null || typeof src !== "object") {
    return src;
  }

  // src가 배열인지 객체인지에 따라 dst를 초기화
  dst = Array.isArray(src) ? [] : {};

  for (const key in src) {
    if (Object.prototype.hasOwnProperty.call(src, key)) {
      // 객체의 각 속성에 대해 깊은 복사를 수행
      dst[key] = deepCopy(src[key], dst[key]);
    }
  }

  return dst;
}

export function isObject(value: any) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

export function isObjectOrArray(value: any) {
  return value !== null && typeof value === "object";
}
