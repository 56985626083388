<template>
  <CModal
    :size="size"
    backdrop="static"
    :visible="isVisible"
    aria-labelledby="CommonModalLabel"
    alignment="center"
    scrollable
    @close="okButtonOnly && okButtonHandler ? okButtonHandler : handleClose"
  >
    <CModalHeader>
      <CModalTitle id="CommonModalLabel">{{ title }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-html="content"> </CModalBody>
    <CModalFooter v-if="okButtonOnly">
      <CButton color="primary" @click="confirm">{{ okButtonName }}</CButton>
    </CModalFooter>
    <CModalFooter v-else>
      <CButton color="secondary" @click="handleClose">{{
        okButtonHandler ? cancelButtonName : okButtonName
      }}</CButton>
      <CButton v-if="okButtonHandler" color="primary" @click="confirm">{{
        okButtonName
      }}</CButton>
    </CModalFooter>
  </CModal>
</template>

<script setup>
import { CModal, CButton } from "@coreui/vue";
import { CModalFooter } from "@coreui/vue-pro/dist/esm/components/modal/index.js";
import { useModalStore } from "~/stores/modal";

// 상태 관리를 통해 모달을 제어
const modalStore = useModalStore();
const isVisible = computed(() => modalStore.isVisible);
const title = computed(() => modalStore.title);
const content = computed(() => modalStore.content);
const size = computed(() => modalStore.size);
const okButtonName = computed(() => modalStore.okButtonName);
const cancelButtonName = computed(() => modalStore.cancelButtonName);
const okButtonHandler = computed(() => modalStore.okButtonHandler);
const okButtonOnly = computed(() => modalStore.okButtonOnly);

// 모달 닫기 및 확인 버튼 로직
const handleClose = () => {
  modalStore.closeModal();
};

const confirm = () => {
  modalStore.confirmModal();
};
</script>
