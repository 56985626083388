<template>
  <CFooter class="px-4">
    <div>
      <span class="ms-1">{{ $t("common.text.copyright") }}</span>
    </div>
    <div class="footer-locale-selector">
      <LocaleSelector class="mx-3" />
    </div>
    <div class="ms-auto">
      <span class="me-1">Powered by</span>
      <a href="https://softminds.net">Softminds</a>
      <span v-if="currentEnvironment !== 'production'">
        ({{ currentEnvironment }})
      </span>
    </div>
  </CFooter>
</template>
<script setup lang="ts">
import { CFooter } from "@coreui/vue/dist/esm/components/footer";
import LocaleSelector from "~/components/controls/LocaleSelector.vue";
const config = useRuntimeConfig();
const currentEnvironment = ref(config.public.env);
</script>
