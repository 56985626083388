import { defineStore } from "pinia";
import { useAuth } from "~/composables/useAuth";

export const useAsyncJobStore = defineStore("asyncJob", {
  state: () => ({
    asyncJobs: null,
    asyncJobPollingTimer: null,
  }),
  actions: {
    async setInitAsyncJobs(asyncJobs) {
      this.asyncJobs = asyncJobs;
      await this.checkAsyncJobs(true);
    },
    async checkAsyncJobs(ignoreLoading = false) {
      const { isAuthenticated } = useAuth();
      if (!isAuthenticated()) {
        if (this.asyncJobPollingTimer) {
          clearInterval(this.asyncJobPollingTimer);
          this.asyncJobPollingTimer = null;
        }
        return;
      }
      if (!ignoreLoading) {
        const response = await customFetch("/async/list", {
          hideLoading: true,
        });
        if (!deepEqual(this.asyncJobs, response.data)) {
          this.asyncJobs = response.data;
        }
      }
      let count = 0;
      for (let i = 0; i < this.asyncJobs.length; i++) {
        const asyncJob = this.asyncJobs[i];
        switch (asyncJob.sttusCode) {
          case "async_job_status.waiting":
          case "async_job_status.ready":
          case "async_job_status.processing":
            count++;
            break;
        }
      }
      if (count > 0) {
        if (!this.asyncJobPollingTimer) {
          this.asyncJobPollingTimer = setInterval(this.checkAsyncJobs, 1000);
        }
      } else if (this.asyncJobPollingTimer) {
        clearInterval(this.asyncJobPollingTimer);
        this.asyncJobPollingTimer = null;
      }
    },
  },
  getters: {
    getAsyncJobs: (state) => state.asyncJobs,
  },
});
