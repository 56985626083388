import { defineStore } from "pinia";

export const useSiteStore = defineStore("site", {
  state: () => ({
    sites: {},
  }),
  actions: {
    setSites(sites) {
      this.sites = sites;
    },
    getSiteAliasById(id) {
      if (id === 0) {
        return "_common_";
      }
      if (this.sites) {
        for (let i = 0; i < this.sites.length; i++) {
          if (this.sites[i].id === id) {
            return this.sites[i].alias;
          }
        }
      }
    },
  },
  getters: {
    getSites: (state) => state.sites,
  },
});
