import { useTabStore } from "~/stores/tab";
import { useModuleStore } from "~/stores/module";
import { useUserStore } from "~/stores/user";
import { splitPath } from "~/composables/string";

export const useQmoduleBase = (props: object) => {
  onMounted(() => {
    console.log(
      "module 공통적으로 실행될 코드",
      moduleId,
      tabStore.selectedTab.moduleId,
    );
    // 여기에 공통적으로 실행할 코드를 작성
  });
  const tabStore = useTabStore();
  const moduleId = tabStore.selectedTab ? tabStore.selectedTab.moduleId : 0;
  const moduleStore = useModuleStore();
  const moduleStack = moduleStore.getModuleStack(moduleId);
  const getCurrentMenu = (menus: any, arr: any) => {
    if (!menus || menus.length === 0 || !arr || arr.length === 0) {
      return undefined;
    }
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].alias === arr[0]) {
        if (arr.length === 1) {
          return menus[i];
        } else {
          arr.splice(0, 1);
          return getCurrentMenu(menus[i].children, arr);
        }
      }
    }
    return undefined;
  };
  const userStore = useUserStore();
  if (userStore.getUser) {
    const route = useRoute();
    const menuArr = splitPath(route.path);
    const currentMenu = getCurrentMenu(userStore.getUser.menus, menuArr);
    const authorities: any = [];
    if (currentMenu && currentMenu.authors) {
      for (let i = 0; i < currentMenu.authors.length; i++) {
        authorities.push(currentMenu.authors[i].alias);
      }
    }
    moduleStack.authorities = authorities;
  }

  const componentMap = {};
  const getQcomponent = (componentType) => {
    if (!componentMap[componentType]) {
      componentMap[componentType] = defineAsyncComponent(
        () => import(`~/components/qcomponents/${componentType}.vue`),
      );
    }
    return componentMap[componentType];
  };

  return {
    moduleStore,
    tabStore,
    moduleId,
    moduleStack,
    props,
    getQcomponent,
  };
};
