<template>
  <CFormSelect v-model="selectedLocaleId" :options="languageOptions">
  </CFormSelect>
</template>
<script setup lang="ts">
import { CFormSelect } from "@coreui/vue/dist/esm/components/form";
import { useI18n } from "vue-i18n";
import { loadCharsts, makeMenuNames } from "~/composables/i18n";
import { useMenusStore } from "~/stores/menu";
import { useLocaleStore } from "~/stores/locale";
const { t } = useI18n();
const languageOptions: any = [];

const locales = localStorage.locales ? JSON.parse(localStorage.locales) : [];
const localeStore = useLocaleStore();
let selectedLocaleId: any;
if (localStorage.locale) {
  const locale = JSON.parse(localStorage.locale);
  selectedLocaleId = ref(locale.id.toString());
  localeStore.setLocale(locale);
} else {
  selectedLocaleId = ref("1");
  for (let i = 0; i < locales.length; i++) {
    if (locales[i].id === 1) {
      localStorage.locale = locales[i];
      localeStore.setLocale(locales[i]);
      break;
    }
  }
}
for (let i = 0; i < locales.length; i++) {
  const locale = locales[i];
  const languageOption = {
    label: locale.localeNm,
    value: locale.id,
  };
  if ("" + selectedLocaleId.value !== "" + locale.id) {
    languageOption.label =
      locale.localeNm + " - " + t("locale.nm." + locale.alias);
  }
  languageOptions.push(languageOption);
}

watch(selectedLocaleId, async (newVal) => {
  for (let i = 0; i < locales.length; i++) {
    if (locales[i].id.toString() === newVal) {
      localStorage.locale = JSON.stringify(locales[i]);
      break;
    }
  }
  await localeStore.getCharsts();
  const menusStore = useMenusStore();
  const menus = menusStore.getMenus;
  makeMenuNames(menus);
  // await menusStore.setMenus(menus);
  for (let i = 0; i < locales.length; i++) {
    if (locales[i].id.toString() === newVal) {
      localeStore.setLocale(locales[i]);
      break;
    }
  }
});
</script>
