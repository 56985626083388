<script setup>
import {
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
  CToastHeader,
} from "@coreui/vue/dist/esm/components/toast/index.js";
import { useToast } from "~/composables/useToast.ts";
const { toastMessages } = useToast();
</script>

<template>
  <CToaster class="p-3" placement="bottom-end">
    <CToast
      v-for="(toast, index) in toastMessages"
      :key="index"
      :color="toast.color"
      :delay="toast.delay ? toast.delay : 5000"
      visible
      class="custom-toast"
    >
      <div
        class="invisible bg-primary bg-secondary bg-success bg-warning bg-info bg-dark bg-danger"
      />
      <div v-if="toast.title || toast.titleHtml">
        <CToastHeader close-button>
          <span v-if="toast.titleHtml" class="me-auto fw-bold" v-html="toast.titleHtml" />
          <span v-else class="me-auto fw-bold">
            {{ toast.title }}
          </span>
          <small v-if="toast.time">{{ toast.time }}</small>
        </CToastHeader>
        <CToastBody v-if="toast.contentHtml">
          <span v-html="toast.contentHtml" />
        </CToastBody>
        <CToastBody v-else>
          {{ toast.content }}
        </CToastBody>
      </div>
      <div v-else class="d-flex">
        <CToastBody v-if="toast.contentHtml">
          <span v-html="toast.contentHtml" />
        </CToastBody>
        <CToastBody v-else>
          {{ toast.content }}
        </CToastBody>
        <CToastClose class="me-2 m-auto" white />
      </div>
    </CToast>
  </CToaster>
</template>

<style scoped>
/* stylelint-disable-next-line */
.bg-primary,
.bg-secondary,
.bg-success,
.bg-warning,
.bg-info,
.bg-dark,
.bg-danger {
  color: white; /* 텍스트 색상을 흰색으로 변경 */
}
.invisible {
  display: none;
}
</style>
